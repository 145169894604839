<template>
	<!-- Header -->
	<header id="header" class="alt">
		<span class="logo">
			<img src="images/alfred-sms.png" alt="" />
		</span>
		<h1>Alfred</h1>
		<p>Un forfait <b>illimité</b> pour vérifier le numéro d’un utilisateur,<br />de manière beaucoup plus sécurisée.</p>
	</header>

	<!-- Nav -->
	<nav id="nav">
		<ul>
			<li><router-link :class="{ active: is_active[0] }" to="/#intro">Alfred, c’est Qui ?</router-link></li>
			<li><router-link :class="{ active: is_active[1] }" to="/#how-it-works">Comment ca marche ?</router-link></li>
			<li><router-link :class="{ active: is_active[2] }" to="/#avantage">Avantages</router-link></li>
			<li><router-link :class="{ active: is_active[3] }" to="/#order-a-demo">Commander une démo</router-link></li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main">

		<section id="intro" class="main">
			<div class="spotlight">
				<div class="content">
					<header class="major">
						<h2>Alfred, c’est Qui ?</h2>
					</header>
					<p>Alfred, c’est un nouveau service de vérification à double facteurs, permettant aux professionnels de vérifier le numéro de téléphone de ses utilisateurs.</p>
				</div>
				<iframe width="560" height="315" src="https://www.youtube.com/embed/o40rr5V_lGc?si=X1G6BqEJYhfYIsE9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
			</div>
		</section>

		<section id="how-it-works" class="main special">
			<header class="major">
				<h2>Comment ca marche ?</h2>
			</header>
			<ul class="features">
				<li>
					<span class="icon solid major style1 fa-code"></span>
					<p>Étape n°1&nbsp;:<br />L’utilisateur fait depuis votre appli / site une demande de vérification de son numéro. Il reçoit un sms contenant un code.</p>
				</li>
				<li>
					<span class="icon major style3 fa-copy"></span>
					<p>Étape n°2&nbsp;:<br />L’utilisateur renseigne le code reçu sur votre appli / site, le valide, puis envoi “Ok” par sms pour valider l’opération à 2 facteurs.</p>
				</li>
				<li>
					<span class="icon major style5 fa-gem"></span>
					<p>Étape n°3&nbsp;:<br />Notre serveur valide le bon code + la réception du sms. Votre utilisateur est bien vérifié, avec un numéro lui appartenant !</p>
				</li>
			</ul>
		</section>

		<section id="avantage" class="main special">
			<header class="major">
				<h2>Avantages d’un pack illimité</h2>
				<p>Nous avons créé Alfred car nous trouvions que la concurrence était beaucoup trop chère pour nous. C’est pourquoi nous avons décidé de faire, pour les petites structures ou start-ups (comme nous), <u>un pack illimité.</u> Le but ? Éviter le tracas de recevoir une facture énorme qui peut passer du simple au quadruple selon le nombre d’utilisateur vérifié... Une variante qui ne nous est pas contrôlable.</p>
				<h2>Nos forfaits Alfred SMS illimités</h2>
				<img src="images/subscripe.svg" />
			</header>
		</section>

		<section id="order-a-demo" class="main special">
			<header class="major">
				<h2>Commander une démo</h2>
				<p>Contactez notre équipe commerciale pour bénéficier d’une API démo de 50 sms offerts. Ce qui permettrait a votre équipe de dev de tester le produit.</p>

				<div class="text-center pa-4">
					<v-btn @click="contact.display = true">Être contacter</v-btn>
					<v-dialog persistent transition="dialog-bottom-transition" v-model="contact.display" width="50%">
						<v-card>
							<v-card-title>Être contacter</v-card-title>
							<v-card-text>
								<p>
									Merci de renseigner vos coordonnées.<br />Notre équipe vous contactera par téléphone ou par courriel.
								</p>
								<v-row dense>
									<v-col cols="12" md="12" sm="12">
										<v-text-field clearable :disabled="contact.sending" label="Nom :" required v-model="contact.name" />
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" md="6" sm="6">
										<v-text-field clearable :disabled="contact.sending" label="Courriel :" required v-model="contact.email" />
									</v-col>
									<v-col cols="12" md="6" sm="6">
										<v-text-field clearable :disabled="contact.sending" label="Numéro de téléphone :" required v-model="contact.phone_number" />
									</v-col>
								</v-row>
								<v-progress-linear indeterminate v-show="contact.sending" />
							</v-card-text>
							<v-card-actions>
								<v-spacer />
								<v-btn color="primary" :disabled="contact.sending" text="Être contacté" variant="tonal" @click="contact_me()" />
								<v-spacer />
								<v-btn color="secondary" :disabled="contact.sending" text="Fermer" variant="outlined" @click="contact.display = false" />
								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog persistent transition="dialog-bottom-transition" v-model="contact.ok" width="30%">
						<v-card>
							<v-card-title>Demande envoyé</v-card-title>
							<v-card-text>
								<span>Votre demande de contact a bien été transmise, merci.</span>
							</v-card-text>
							<v-card-actions>
								<v-spacer />
								<v-btn color="primary" text="Fermer" variant="tonal" @click="contact_ok()" />
								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-dialog>
				</div>
			</header>
		</section>
	</div>
</template>

<script>
import Api from '@/Api.js'

// @ is an alias to /src
export default {
	beforeRouteUpdate(to) {
		this.is_active[0] = to.hash == "" || to.hash == "#intro"
		this.is_active[1] = to.hash == "#how-it-works"
		this.is_active[2] = to.hash == "#avantage"
		this.is_active[3] = to.hash == "#order-a-demo"
	},
	data() {
		return {
			contact: {
				display: false,
				email: null,
				name: null,
				ok: false,
				phone_number: null,
				sending: false
			},
			is_active: [true, false, false, false]
		}
	},
	methods: {
		contact_me() {
			this.contact.sending = true
			let api = Api.get_instance()
			api.do_contact_me(this.contact.name, this.contact.email, this.contact.phone_number)
				.then(() => {
					this.contact.sending = false
					this.contact.ok = true
				})
				.catch(() => {
					this.contact.display = false
					this.contact.sending = false
				})
		},
		contact_ok() {
			this.contact.display = false
			this.contact.email = null
			this.contact.name = null
			this.contact.ok = false
			this.contact.phone_number = null
		}
	},
	mounted() {
		this.is_active[0] = this.$route.hash == "" || this.$route.hash == "#intro"
		this.is_active[1] = this.$route.hash == "#how-it-works"
		this.is_active[2] = this.$route.hash == "#avantage"
		this.is_active[3] = this.$route.hash == "#order-a-demo"
	},
	name: 'HomeView'
}
</script>
